import { GlobalStyles } from '@mui/material';
import React from 'react';

const GlobalThemeStyles = () => {
  return (
    <GlobalStyles
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
        },
        html: {
          width: '100%',
          height: '100%',
          WebkitOverflowScrolling: 'touch',
        },
        body: {
          width: '100%',
          height: '100%',
        },
        '#root': {
          width: '100%',
          height: '100vh',
        },
        img: { display: 'block', maxWidth: '100%' },
      }}
    />
  );
};

export default GlobalThemeStyles;
