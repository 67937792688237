import React from 'react';
import useStyles from './styles';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';
import SkeletonImage from '../SkeletonImage';
import { IS_ADMIN } from '../../utils/appUtills';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';

const ProductCard = (props) => {
  const { data, onClick } = props;
  const classes = useStyles();

  return (
    <Stack>
      <Stack width='100%' className={classes.item_container}>
        {IS_ADMIN ? (
          <div className={classes.editContainer} onClick={onClick}>
            <FontAwesomeIcon icon={faPenToSquare} />
          </div>
        ) : null}
        <SkeletonImage
          src={`/products-img/${data['Item key']}.jpg`}
          alt={data['Item name']}
          className={classes.item_img}
        />
      </Stack>
      <Stack>
        <Typography variant='body2'>{data['Item name']}</Typography>
      </Stack>
    </Stack>
  );
};

ProductCard.propTypes = {
  data: PropTypes.object,
};

export default ProductCard;
