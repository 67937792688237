import { Pagination, PaginationItem, Stack } from '@mui/material';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { PAGE_SIZE } from '../../utils/appUtills';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const useStyles = makeStyles((theme) => ({
  pagination_root: {
    '& .MuiButtonBase-root': {
      '&.MuiPaginationItem-root': {
        fontSize: 14,
        fontWeight: 500,
        '&.Mui-selected': {
          color: 'white',
          background: theme.palette.common.slatBlue,
        },
      },
    },
  },
}));

const CustomPagination = (props) => {
  const { pageCount, pageSize, totalRecords, handleChange } = props;
  const className = useStyles();
  const count = Math.ceil(totalRecords / (pageSize || PAGE_SIZE)) || 0;

  return (
    <>
      <Stack spacing={2}>
        <Pagination
          size='large'
          count={count}
          page={pageCount}
          onChange={(e, page) => handleChange(e, page)}
          classes={{ root: className.pagination_root }}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              components={{
                previous: () => <FontAwesomeIcon icon={faChevronLeft} />,
                next: () => <FontAwesomeIcon icon={faChevronRight} />,
              }}
            />
          )}
        />
      </Stack>
    </>
  );
};

CustomPagination.propTypes = {
  pageCount: PropTypes.number,
  pageSize: PropTypes.number,
  totalRecords: PropTypes.number,
  handleChange: PropTypes.func,
};

export default CustomPagination;
