import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';

const ThemeConfig = (props) => {
  const { children } = props;

  const themeOptions = {
    breakpoints,
    palette,
    typography,
  };

  const theme = createTheme(themeOptions);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

ThemeConfig.propTypes = {
  children: PropTypes.node,
};

export default ThemeConfig;
