import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  item_container: {
    backgroundColor: theme.palette.common.softPeach,
    alignItems: 'center',
    justifyContent: 'center',
    height: 200,
    padding: 20,
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      height: 275,
    },
    [theme.breakpoints.up('sm')]: {
      height: 250,
    },
  },
}));
