import { makeStyles } from '@mui/styles';
import { Form, FormikProvider, useFormik } from 'formik';
import {
  Box,
  Slide,
  Modal,
  Typography,
  Backdrop,
  Stack,
  IconButton,
  TextField,
  Switch,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { Strings } from '../../utils/strings';
import { updateProduct } from '../../firebase';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

// === Styles ===
const useStyles = makeStyles((theme) => ({
  content_container: {
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translate(-50%)',
    width: 450,
    height: '100%',
    background: '#fff',
    boxShadow: 24,
    padding: '36px 24px',
  },
  visibleTitle: {
    fontWeight: '300 !important',
    fontSize: '16px !important',
  },
}));

// ===== Main Component =====
const AddProductModal = (props) => {
  const { open, handleClose, selectedData } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      productName: '',
      itemKey: '',
      sortCode: '',
      cut: '',
      visible: false,
    },
    // validationSchema: editProductSchema,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const { errors, values, touched, setFieldValue, handleChange, handleSubmit } =
    formik;

  const handleFormSubmit = (values) => {
    setLoading(true);
    let data = {
      'Item name': values.productName,
      'Item key': values.itemKey,
      'Sort code': values.sortCode,
      cut: values.cut,
      visible: values.visible,
    };

    updateProduct(selectedData.id, data, (res) => {
      toast.success('product updated successfully.');
      if (Object.entries(res).length) {
        const newData = props?.allProducts?.map((el) => {
          if (el.id === res.id) el = res;
          return el;
        });
        props?.setAllProducts(newData);
      }
      setLoading(false);
      handleClose();
    },
    (err)=> {
      setLoading(false);
      toast.error(err);
    }
    );
  };

  useEffect(() => {
    if (selectedData && Object?.keys(selectedData).length > 0) {
      setFieldValue('productName', selectedData['Item name'] || '');
      setFieldValue('itemKey', selectedData['Item key'] || '');
      setFieldValue('sortCode', selectedData['Sort code'] || '');
      setFieldValue('cut', selectedData['cut'] || '');
      setFieldValue('visible', selectedData['visible'] || false);
    }
  }, [selectedData]);

  return (
    <Modal
      aria-labelledby='edit-product-modal'
      aria-describedby='edit-product-modal-description'
      open={open}
      // onClose={handleClose}
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Slide in={open} direction={'left'} timeout={700}>
        <Box className={classes.content_container}>
          <Stack
            direction='row'
            flex={1}
            alignItems='center'
            justifyContent={'space-between'}
          >
            <Typography
              id='transition-modal-title'
              variant='h6'
              color={'textColor.dark'}
            >
              Edit Product
            </Typography>
            <IconButton onClick={handleClose}>
            <FontAwesomeIcon icon={faXmark} />
            </IconButton>
          </Stack>
          <div style={{ marginTop: 10 }}>
            <FormikProvider value={formik}>
              <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
                <TextField
                  fullWidth
                  variant='outlined'
                  type='text'
                  name={'productName'}
                  value={values.productName}
                  label={Strings.PRODUCT_NAME}
                  placeholder={Strings.ENTER_PRODUCT}
                  onChange={handleChange('productName')}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(touched.productName && errors.productName)}
                  helperText={touched.productName && errors.productName}
                  margin={'dense'}
                />
                <TextField
                  fullWidth
                  variant='outlined'
                  type='text'
                  name={'itemKey'}
                  value={values.itemKey}
                  label={'Item Key'}
                  placeholder={'Enter Item Key'}
                  onChange={handleChange('itemKey')}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(touched.itemKey && errors.itemKey)}
                  helperText={touched.itemKey && errors.itemKey}
                  margin={'dense'}
                />
                <TextField
                  fullWidth
                  variant='outlined'
                  type='text'
                  name={'sortCode'}
                  value={values.sortCode}
                  label={'Sort Code'}
                  placeholder={'Enter Sort Code'}
                  onChange={handleChange('sortCode')}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(touched.sortCode && errors.sortCode)}
                  helperText={touched.sortCode && errors.sortCode}
                  margin={'dense'}
                />
                <TextField
                  fullWidth
                  variant='outlined'
                  type='text'
                  name={'cut'}
                  value={values.cut}
                  label={'cut'}
                  placeholder={'Enter cut'}
                  onChange={handleChange('cut')}
                  InputLabelProps={{ shrink: true }}
                  error={Boolean(touched.cut && errors.cut)}
                  helperText={touched.cut && errors.cut}
                  margin={'dense'}
                />
                <Stack
                  direction='row'
                  alignItems='center'
                  justifyContent='space-between'
                  gap={2}
                  mt={1.5}
                >
                  <Typography
                    variant='body1'
                    fontWeight={300}
                    color='textColor.main'
                  >
                    {Strings.IS_VISIBLE}
                  </Typography>
                  <Switch
                    name='voiTransaction'
                    checked={values.visible}
                    onChange={(e, checked) => setFieldValue('visible', checked)}
                  />
                </Stack>
                <LoadingButton
                  fullWidth
                  type='submit'
                  variant='contained'
                  sx={{ mt: 3 }}
                  loading={loading}
                  disabled={loading}
                >
                  {Strings.SAVE}
                </LoadingButton>
              </Form>
            </FormikProvider>
          </div>
        </Box>
      </Slide>
    </Modal>
  );
};

export default AddProductModal;
