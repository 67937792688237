export const Strings = {
  // login
  LOGIN: 'Login',
  EMAIL: 'Email',
  ENTER_EMAIL: 'Enter Email',
  PASSWORD: 'Password',
  ENTER_PASSWORD: 'Enter Password',
  LOGOUT: 'Logout',
  SURE_LOGOUT: 'Are you sure you want to logout?',
  SAVE: 'Save',
  PRODUCT_NAME: 'Product Name',
  ENTER_PRODUCT: 'Enter Product Name',
  IS_VISIBLE: 'Visible',

  //  products
  NO_PRODUCTS_YET: 'No products yet',
};
