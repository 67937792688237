// change value based on requirement or deploy site.
export const IS_ADMIN = process.env.REACT_APP_IS_ADMIN === 'true' || false;

export const PAGE_SIZE = 25;

// frontend pagination functionality
export const handlePaginationData = (arr, pageNo, pageSize) => {
  return new Promise((resolve, reject) => {
    try {
      if (arr?.length) {
        let startAt = pageNo * pageSize - pageSize;
        let endAt = pageNo * pageSize;
        let newData = structuredClone(arr)?.slice(startAt, endAt);
        resolve(newData);
      } else {
        resolve(arr);
      }
    } catch (error) {
      reject(error);
    }
  });
};
