import { Container, Grid, Stack } from '@mui/material';
import React from 'react';
import CategoryCard from '../../components/CategoryCard';
import { useNavigate } from 'react-router-dom';
import categories from '../../data/categories.json';

const Category = () => {
  const navigate = useNavigate();

  return (
    <Container>
      <Stack pb={2.5}>
        <Grid container spacing={2}>
          {categories?.map((el, index) => {
            return (
              <Grid item key={index} xs={6} sm={4} md={3} lg={2.4}>
                <CategoryCard
                  data={{ ...el }}
                  onClick={() =>
                    navigate('/products', {
                      state: { category_id: el?.category_id },
                    })
                  }
                />
              </Grid>
            );
          })}
        </Grid>
      </Stack>
    </Container>
  );
};

export default Category;
