import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { Skeleton } from '@mui/material';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  common_img: {
    width: '100%',
    objectFit: 'contain',
  },
  skeleton_class: {
    '&.MuiSkeleton-root': {
      height: '100%',
      background: 'rgba(0, 0, 0, 0.03)',
    },
  },
}));

const SkeletonImage = (props) => {
  const { src, alt, height, width, className } = props;
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [imgNotExist, setImgNotExist] = useState(false);

  useEffect(() => {
    // setLoading(true);
    setImgNotExist(false);
  }, [src]);

  return (
    <>
      {/* <Skeleton
        animation='wave'
        height={height}
        width={width}
        variant={skeletonVariant || 'rectangular'}
        style={{ display: loading ? 'block' : 'none' }}
        className={classNames(classes.skeleton_class, {
          [className]: className,
        })}
      /> */}

      {!imgNotExist ? (
        <img
          src={src}
          alt={alt || 'img'}
          height={height}
          width={width}
          className={classNames(classes.common_img, {
            [className]: className,
          })}
          style={{
            display: imgNotExist ? 'none' : !loading ? 'block' : 'none',
          }}
          onLoad={() => setLoading(false)}
          onError={() => {
            setLoading(false);
            setImgNotExist(true);
          }}
        />
      ) : null}
    </>
  );
};

SkeletonImage.propTypes = {
  src: PropTypes.string,
  height: PropTypes.string | PropTypes.number,
  width: PropTypes.string | PropTypes.number,
  skeletonVariant: PropTypes.string,
  className: PropTypes.string,
  alt: PropTypes.string,
};

export default SkeletonImage;
