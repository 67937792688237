const PRIMARY = {
  light: '#42a5f5',
  main: '#1976d2',
  dark: '#1565c0',
  contrastText: '#fff',
};

const SECONDARY = {
  light: '#ba68c8',
  main: '#9c27b0',
  dark: '#7b1fa2',
  contrastText: '#fff',
};

const SUCCESS = {
  light: '#4caf50',
  main: '#2e7d32',
  dark: '#1b5e20',
  contrastText: '#fff',
};

const ERROR = {
  light: '#ef5350',
  main: '#d32f2f',
  dark: '#c62828',
  contrastText: '#fff',
};

const WARNING = {
  light: '#ff9800',
  main: '#ed6c02',
  dark: '#e65100',
  contrastText: '#fff',
};

const INFO = {
  light: '#03a9f4',
  main: '#0288d1',
  dark: '#01579b',
  contrastText: '#fff',
};

const palette = {
  common: {
    black: '#000',
    white: '#fff',
    softPeach: '#efefe9',
    iceBlue: '#e7eeff',
    slatBlue: '#5f78dd',
  },
  primary: { ...PRIMARY },
  secondary: { ...SECONDARY },
  success: { ...SUCCESS },
  error: { ...ERROR },
  warning: { ...WARNING },
  info: { ...INFO },
};

export default palette;
