import {
  CircularProgress,
  Container,
  Grid,
  Stack,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProductCard from '../../components/ProductCard';
import { getProductsList } from '../../firebase';
import CustomPagination from '../../components/CustomPagination';
import { PAGE_SIZE, handlePaginationData } from '../../utils/appUtills';
import { toast } from 'react-toastify';
import AddProductModal from '../../sections/products/addProductModal';
import { useLocation } from 'react-router-dom';
import { Strings } from '../../utils/strings';

const firstShowItemKey = ['8690983049794', '8690983048100', '8690983048124'];

const Products = () => {
  const { state } = useLocation();
  const [allProducts, setAllProducts] = useState([]);
  const [listedProducts, setListedProducts] = useState([]);
  const [pageNumber, setPageNumber] = useState(1); // Initial page number
  const [loading, setLoading] = useState(true);
  const [openProductDialog, setOpenProductDialog] = useState(false);
  const [selectedData, setSelectedData] = useState({});

  const fetchProducts = (catID) => {
    getProductsList(
      catID,
      (data) => {
        let result = [];
        if (data && data?.length) {
          const specificItems = data.filter((item) =>
            firstShowItemKey.includes(item['Item key'])
          );
          const otherItems = data.filter(
            (item) => !firstShowItemKey.includes(item['Item key'])
          );
          result = specificItems.concat(otherItems);
        }

        setAllProducts(result);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      },
      (err) => {
        setLoading(false);
        toast.error(err);
      }
    );
  };

  useEffect(() => {
    if (state && state?.category_id) {
      fetchProducts(state?.category_id);
    }
  }, [state]);

  useEffect(() => {
    if (allProducts?.length && pageNumber) {
      handlePaginationData(allProducts, pageNumber, PAGE_SIZE)
        .then((res) => {
          setListedProducts(res);
        })
        .catch((err) => toast.error(err));
    }
  }, [allProducts, pageNumber]);

  const handleCloseDialog = () => {
    setSelectedData('');
    setOpenProductDialog(false);
  };

  if (loading) {
    return (
      <Stack flex={1} alignItems='center' justifyContent='center'>
        <CircularProgress color='primary' />
      </Stack>
    );
  }

  if (!listedProducts?.length) {
    return (
      <Stack alignItems='center' justifyContent='center' flex={1}>
        <Typography>{Strings.NO_PRODUCTS_YET}</Typography>
      </Stack>
    );
  }

  return (
    <Container>
      <Stack pb={2.5} gap={2}>
        <Grid container spacing={2}>
          {listedProducts?.map((el, index) => {
            return (
              <Grid item key={index} xs={6} sm={4} md={3} lg={2.4}>
                <ProductCard
                  onClick={() => {
                    setOpenProductDialog(true);
                    setSelectedData(el);
                  }}
                  data={{ ...el }}
                />
              </Grid>
            );
          })}
        </Grid>
        {allProducts?.length ? (
          <Stack flex={1} alignItems='center'>
            <CustomPagination
              pageCount={pageNumber}
              totalRecords={allProducts?.length || 0}
              handleChange={(e, pageNo) => setPageNumber(pageNo)}
            />
          </Stack>
        ) : null}
      </Stack>
      {openProductDialog && (
        <AddProductModal
          open={openProductDialog}
          handleClose={handleCloseDialog}
          selectedData={selectedData}
          allProducts={allProducts}
          setAllProducts={setAllProducts}
        />
      )}
    </Container>
  );
};

export default Products;
