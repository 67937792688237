// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import {
  getFirestore,
  collection,
  getDocs,
  query,
  updateDoc,
  doc,
  getDoc,
  where,
} from 'firebase/firestore';
import { IS_ADMIN } from './utils/appUtills';

const firebaseConfig = {
  apiKey: 'AIzaSyA6f2O2SlHOpvk7M3Q0LMpsp40CPdG2-ps',
  authDomain: 'product-site-2b0df.firebaseapp.com',
  projectId: 'product-site-2b0df',
  storageBucket: 'product-site-2b0df.appspot.com',
  messagingSenderId: '585575370705',
  appId: '1:585575370705:web:1f7e13f5b595afc7635af0',
  measurementId: 'G-E7TY7ZS7K8',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
export const db = getFirestore(app);

// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);

// login handle
export const login = (data, onSuccess, onError) => {
  const { email, password } = data;
  signInWithEmailAndPassword(auth, email, password)
    .then((userCredential) => {
      const user = userCredential.user;
      localStorage.setItem('jwtToken', user?.accessToken);
      onSuccess?.();
    })
    .catch((error) => {
      const errorCode = error.code;
      switch (errorCode) {
        case 'auth/user-not-found':
          onError?.('User Not Found!');
          break;
        case 'auth/wrong-password':
          onError?.('Password not match!');
          break;
        case 'auth/invalid-credential':
          onError?.('Invalid credential. Please try again.');
          break;
        default:
          onError?.('Authentication failed. Please try again later.');
          break;
      }
    });
};

// get products by category
export const getProductsList = async (cat_id, onSuccess, onError) => {
  try {
    const colRef = collection(db, 'products');

    let querySnapshot;
    if (IS_ADMIN) {
      const q = query(colRef, where('Sort code', '==', cat_id));
      querySnapshot = await getDocs(q);
    } else {
      const q = query(
        colRef,
        where('Sort code', '==', cat_id),
        where('visible', '==', true),
        where('visible', '!=', null)
      );
      querySnapshot = await getDocs(q);
    }

    const documents = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    onSuccess?.(documents || []);
  } catch (error) {
    switch (error.code) {
      case 'permission-denied':
        onError?.(
          "Permission denied. You don't have access to the requested resource."
        );
        break;
      case 'unavailable':
        onError?.('Service unavailable. Please try again later.');
        break;
      default:
        console.error('Error getting documents: ', error);
        onError?.('Error fetching products list. Please try again later.');
        break;
    }
  }
};

// get data based on docRef
const getDataByDocRef = (docRef, onSuccess, onError) => {
  getDoc(docRef)
    .then((docSnapshot) => {
      if (docSnapshot.exists()) {
        const id = docSnapshot.id;
        const data = docSnapshot.data();
        onSuccess?.({ ...data, id });
      } else {
        onError?.('No such document!');
        console.log('No such document!');
      }
    })
    .catch((error) => {
      onError?.(error);
      console.error('Error getting document:', error);
    });
};

// update product info
export const updateProduct = (docId, data, onSuccess, onError) => {
  try {
    if (docId) {
      // Get a reference to the document
      const docRef = doc(db, 'products', docId);

      // Update the document with the new data
      updateDoc(docRef, data)
        .then(async () => {
          await getDataByDocRef(docRef, onSuccess, onError);
        })
        .catch((error) => {
          onError?.('Error updating document: ');
          console.error('Error updating document: ', error);
        });
    } else onError?.('Please provide docId');
  } catch (error) {
    onError?.(error);
    console.error('Error updating document: ', error);
    throw error;
  }
};

export default app;
