import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import MainLayout from './layouts/MainLayout';
import Products from './pages/Products';
import Category from './pages/Categories';
import Companies from './pages/Companies';
import setAuthToken from './utils/setAuthToken';
import { jwtDecode } from 'jwt-decode';
import Login from './pages/auth/login';
import AuthLayout from './layouts/AuthLayout';
import { IS_ADMIN } from './utils/appUtills';
import { toast } from 'react-toastify';

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwtDecode(token);
  // store.dispatch(setLogin(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    // store.dispatch(logout());
    toast.error('Token expired');
    window.location.href = '/login';
    localStorage.clear();
  }
}

const userRoute = {
  path: '/',
  element: <MainLayout />,
  children: [
    { path: '/', element: <Category /> },
    { path: '/products', element: <Products /> },
  ],
};

const Routes = () => {
  return useRoutes([
    IS_ADMIN
      ? localStorage.jwtToken
        ? {
            path: '/',
            element: <MainLayout />,
            children: [
              { path: '/', element: <Navigate to='/categories' /> },
              { path: '/categories', element: <Category /> },
              { path: '/companies', element: <Companies /> },
              { path: '/products', element: <Products /> },
            ],
          }
        : {
            path: '/',
            element: <AuthLayout />,
            children: [
              { path: '/', element: <Navigate to='/login' /> },
              { path: '/login', element: <Login /> },
            ],
          }
      : { ...userRoute },

    { path: '*', element: <Navigate to='/' replace /> },
  ]);
};

export default Routes;
