import React from 'react';
import useStyles from './styles';
import PropTypes from 'prop-types';
import { Stack, Typography } from '@mui/material';

const CategoryCard = (props) => {
  const { data, onClick } = props;
  const classes = useStyles();

  return (
    <Stack className={classes.item_container} onClick={onClick}>
      <Typography textAlign='center'>{data.category_name || ''}</Typography>
      {data?.quantity ? (
        <Typography textAlign='center'>{data.quantity}</Typography>
      ) : null}
    </Stack>
  );
};

CategoryCard.propTypes = {
  data: PropTypes.object,
  onClick: PropTypes.func,
};

export default CategoryCard;
