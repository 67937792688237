import { BrowserRouter } from 'react-router-dom';
import Routes from './routes';
import ThemeConfig from './theme';
import GlobalThemeStyles from './theme/globalThemeStyles';
import { Slide, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ThemeConfig>
      <ToastContainer
        position='top-right'
        autoClose={2500}
        limit={3}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        draggable
        theme='colored'
        transition={Slide}
      />
      <GlobalThemeStyles />
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </ThemeConfig>
  );
}

export default App;
