import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  item_container: {
    backgroundColor: theme.palette.common.softPeach,
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    height: 200,
    padding: 20,
    // borderRadius: 4,
    // boxShadow:
    //   'rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 4px 0px',
    [theme.breakpoints.up('md')]: {
      height: 275,
    },
    [theme.breakpoints.up('sm')]: {
      height: 250,
    },
  },
  item_img: {
    height: 150,
    width: 150,
  },
  editContainer: {
    position: 'absolute',
    top: 10,
    right: 10,
    '& :hover': {
      cursor: 'pointer',
    },
  },
}));
