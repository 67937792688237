import React from 'react';
// import PropTypes from 'prop-types';
import { Container, Stack } from '@mui/material';

const Navbar = (props) => {
  return (
    <Container>
      <Stack alignItems='center' justifyContent='center' py={2.5}>
        <img src='taya-logo.png' alt='taya-logo' width={100} />
      </Stack>
    </Container>
  );
};

// Navbar.propTypes = {};

export default Navbar;
