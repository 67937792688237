import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
  layout_outer_container: {
    width: '100vw',
    height: '100vh',
    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
  },
  layout_inner_container: {
    flex: 1,
    height: '100%',
    width: '100%',
    overflow: 'auto',
  },
}));
