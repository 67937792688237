import React from 'react';
import useStyles from './styles';
import { Outlet } from 'react-router-dom';
import { Stack } from '@mui/material';
import Navbar from '../Navbar';

const MainLayout = () => {
  const classes = useStyles();

  return (
    <Stack className={classes.layout_outer_container}>
      <Navbar />
      <Stack className={classes.layout_inner_container}>
        <Outlet />
      </Stack>
    </Stack>
  );
};

export default MainLayout;
